
import Http from "../helpers/Http";

class BookingService {

  get(data) {
    return Http.post("/reports/bookings/detailed/", data);
  }
  
  getSales(data) {
    return Http.post("/reports/sales/detailed/", data);
  }  

  getBookingAnalysis(data) {
    return Http.post("/reports/bookingAnalysis/", data);
  } 

  getPaymentSuppliers(data) {
    return Http.post("/reports/paymentSuppliers/", data);
  }
  
  getAccountsReceivable(data) {
    return Http.post("/reports/accountsReceivable/", data);
  }

  getServicesByBooking(data) {
    return Http.post("/reports/servicesPerBooking/", data);
  }

  getStatsLocations(data) {
    return Http.post("/reports/statsLocations/", data);
  }

  getStatsServTypes(data) {
    return Http.post("/reports/statsServTypes/", data);
  }

  getStatsSuppliers(data) {
    return Http.post("/reports/statsSuppliers/", data);
  }

  getStatSupplierServTypeSell(data) {
    return Http.post("/reports/statSupplierServTypeSell/", data);
  }

  getStatsLocationsBySupplier(data){
    return Http.post("/reports/statsLocationsBySupplier/", data);
  }
  
  getBookingFinancialsAnaysis(data) {
    return Http.post('/reports/bookingFinancialsAnaysis', data);
  }

  getTariffNonAcc(data) {
    return Http.post('/reports/tariffNonAcc', data);
  }
  
  getSalesAchivied(data) {
    return Http.post('/reports/salesAchivied', data);
  }

  getPaxPerYear(data) {
    return Http.post('/dashboard/paxMonth', data);
  }

  getCostVsRetail(data) {
    return Http.post('/dashboard/costVsRetail', data);
  }

  salesDirecVsIndirect(data) {
    return Http.post('/dashboard/salesDirectvsIndirect', data);
  }

  getQtyPax(data) {
    return Http.post('/dashboard/quatityTravelers', data);
  }

  getTotalCostSales(data) {
    return Http.post('/dashboard/totalCost', data);
  }

  getTotalMarginSales(data) {
    return Http.post('/dashboard/margin', data);
  }
  
  getSalesRecVsInv(data) {
    return Http.post('/dashboard/salesInvVsRec', data);
  }

  getSalesRecVsInvByAgencies(data) {
    return Http.post('/dashboard/salesInvVsReGrpAngency', data);
  }

  getKpisByYear(data) {
    return Http.post('/dashboard/sdKPIS', data);
  }
  
  getSupplierKpis(data){
    return Http.post('/dashboard/suppliersKPIS', data);
  }

  getConsultSuperHabit(data) {
    return Http.post('/dashboard/sdKPIS', data);
  }

  getDashboardGeneral(data) {
    return Http.post('/dashboard/IncVsOut', data);
  }

}

export default new BookingService();