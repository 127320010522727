<template>
<div class="table-content">
        <div class="table-options">
            <div class="delete" v-if="selected.length > 0">
                <vs-icon icon="delete"></vs-icon>
            </div>
        </div>
        <div class="table-wrapper with-border">
            <vs-table :data="bookings">
                <template slot="thead">
                    <vs-th sort-key="EstmPayDate">Pago Estimado</vs-th>
                    <!-- <vs-th sort-key="FechaPagoRealizado">Fecha Deposito</vs-th> -->
                    <vs-th sort-key="BookingReference">Reference</vs-th>
                    <!-- <vs-th sort-key="BookingName">Booking Name</vs-th> -->
                    <vs-th sort-key="TravelDate">Travel Date</vs-th>
                    <vs-th sort-key="SupplierName">Supplier</vs-th>
                    <vs-th sort-key="Original_Costs_Inc">Fecha IN</vs-th>
                    <vs-th sort-key="Expected_Sales_Inc">Dias</vs-th>
                    <vs-th sort-key="Actual_Sales_Inc"># Vou</vs-th>
                    <vs-th sort-key="RETAILREAL">Prog</vs-th>
                    <vs-th sort-key="Booking_Receipt_Inc">Pagado </vs-th>
                    <vs-th sort-key="MARGIN">Estimado </vs-th>
                    <vs-th sort-key="MARGIN">Facturado </vs-th>
                    <vs-th sort-key="MARGIN">Pagado </vs-th>
                    <vs-th sort-key="MARGIN">Saldo </vs-th>
                    <vs-th class="center"></vs-th>
                </template>
                <template slot-scope="{ data }">
                    <template :data="tr"  v-for="(tr, indextr) in data">
                        <vs-tr :key="indextr" :class="{'active': opened.includes(data[indextr].id)}">
                            <vs-td :data="data[indextr].EstmPayDate"> 
                                {{ data[indextr].EstmPayDate | moment('timezone', 'Etc/GMT', 'll')}}
                            </vs-td>
                            <vs-td :data="data[indextr].BookingReference"> 
                                {{ data[indextr].BookingReference }}
                            </vs-td>
                            <vs-td :data="data[indextr].TravelDate"> 
                                {{ data[indextr].TravelDate | moment('timezone', 'Etc/GMT', 'll')}} 
                            </vs-td>
                            <vs-td :data="data[indextr].SupplierCode">
                                 {{ data[indextr].SupplierCode }} 
                            </vs-td>
                            <vs-td :data="data[indextr].ServiceDateIn">
                                {{ data[indextr].ServiceDateIn | moment('timezone', 'Etc/GMT', 'll')}} 
                            </vs-td>
                            <vs-td :data="data[indextr].SPDays"> 
                                {{ data[indextr].SPDays }} 
                            </vs-td>
                            <vs-td :data="data[indextr].Voucher"> 
                                {{ data[indextr].Voucher }} 
                            </vs-td>
                            <vs-td :data="data[indextr].Programacion" :class="data[indextr].Programacion == 'Si' ? 'success' : 'alert'"> 
                                {{ data[indextr].Programacion }} 
                            </vs-td>
                            <vs-td :data="data[indextr].Programacion" :class="data[indextr].Pagado == 'Si' ? 'success' : 'alert'">     
                                {{ data[indextr].Pagado }} 
                            </vs-td>
                            <vs-td :data="data[indextr].Cost"> 
                                {{ $n(data[indextr].Cost, 'currency') }} 
                            </vs-td>

                            <vs-td :data="data[indextr].invoiceAmount" :class="[{'pending': data[indextr].Cost > data[indextr].invoiceAmount}]"> 
                                {{ $n(data[indextr].invoiceAmount, 'currency') }} 
                            </vs-td>
                            
                            <vs-td :data="data[indextr].chequeAmount"> 
                                {{ $n(data[indextr].chequeAmount, 'currency') }} 
                            </vs-td>


                            <vs-td :data="data[indextr].Pagado" 
                                :class="data[indextr].invoiceAmount - data[indextr].chequeAmount > 1 ? 'alert' : 'success'"> 
                                    {{ $n(data[indextr].invoiceAmount - data[indextr].chequeAmount, 'currency')}}
                            </vs-td>

                            <vs-td class="options">
                                <vs-icon icon="expand_more" @click="toggleRow(indextr)"></vs-icon>
                            </vs-td> 
                        </vs-tr>
                        <vs-tr :key="`sub-${indextr}`" v-if="opened.includes(indextr)">
                            <vs-td colspan="14" class="noLineClamp">
                                <div class="bookingInfo">
                                    <div class="travel-info">
                                        <div class="item">
                                            <span class="subtitle">Booking Name</span>
                                            <p>{{  data[indextr].BookingName }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Transfer Bank</span>
                                            <p>{{  data[indextr].bancotransferencia  }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Supplier Bank</span>
                                            <p>{{  data[indextr].BANK }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Supplier Bank Account</span>
                                            <p>{{  data[indextr].BANK_ACCOUNT }}</p>
                                        </div>
                                    </div>
                                    <div class="service">
                                        <div class="notes">
                                            <div class="subtitle">Booking Status</div>
                                            <p>{{ data[indextr].BookingStatus }}</p>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Service</div>
                                            <p>{{ data[indextr].SERVICE }}</p>
                                            <span class="voucher">Terms: {{ data[indextr].SPTerms }}</span>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Service Status</div>
                                            <p>{{ data[indextr].SrvStatus }}</p>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Supplier Code</div>
                                            <p>{{ data[indextr].SupplierName }}</p>
                                            <span class="voucher">Código: {{ data[indextr].SupplierCode }}</span>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Agencia</div>
                                            <p>{{ data[indextr].AgentName }}</p>
                                            <span class="voucher">Código: {{ data[indextr].AgentCode }}</span>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Agente</div>
                                            <p>{{ data[indextr].ConsultantName }}</p>
                                            <span class="voucher">Código: {{ data[indextr].ConsultantCode }}</span>
                                        </div>
                                    </div>
                                </div>
                            </vs-td>
                        </vs-tr>
                    </template>
                </template>
                <tr>
                    <td colspan=""></td>
                </tr>
            </vs-table>
        </div>
    </div>
</template>
<script>
export default {
    name: "TablePaymentSupplier",
    props: {
        items: { type: Array, default: () => [] },
    },
    data () {
        return {
            opened: [],
            selected:[],
            bookings: this.items,
        }
    },
    watch: {
        items(val){
            this.bookings = val
        }
    },
    methods: {
        toggleRow(id){
            const index   = this.opened.indexOf(id)
            this.opened = []
            if (index > -1) {
                this.opened.splice(index, 1)
                this.activeRow = -1
            } else {
                this.opened.push(id)
                this.activeRow = id
            }
        }
    }
}
</script>