<template>
    <div class="dashboard bookings sales analysis">
        <div class="head">
            <div class="title">Payments to Suppliers</div>
        </div>

        <div class="summary" v-if="totalEstimated > 0">
            <div class="item">
                <div class="count">{{ $n(totalEstimated, 'currency') }}</div>
                <div class="name">Estimated</div>
            </div>
            <div class="item">
                <div class="count">{{ $n(totalInv, 'currency') }}</div>
                <div class="name">Invoiced</div>
            </div>
            <div class="item">
                <div class="count">{{ $n(totalPaid, 'currency') }}</div>
                <div class="name">Paid</div>
            </div>
            <div class="item">
                <div class="count">{{ $n(pendingCost, 'currency') }}</div>
                <div class="name">Balance</div>
            </div>
        </div>

        <div class="box-white filter">
            <vs-collapse>
                <vs-collapse-item icon-arrow="expand_more">
                    <div slot="header">
                        Buscador
                    </div>
                    <div class="wrapper">
                        <div class="item">
                            <vs-input label="Referencia" v-model="reference"/>
                        </div>
                        <div class="item">
                            <div class="wrap">
                                <label for="">Fechas</label>
                                <vs-select  class="creSelect" v-model="typeDate">
                                    <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in filterDates" />
                                </vs-select>  
                            </div>
                            <input-date-picker v-model="date" :currentDate="date"></input-date-picker>                
                        </div>
                        
                        <div class="item">
                            <vs-input label="# Voucher" v-model="voucher"/>
                        </div>

                        <div class="item">
                            <label for="">Estado Reservación</label>
                            <multiselect v-model="stBookings" :options="bookStatus" :multiple="true" :max-height="160" placeholder="" 
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="DESCRIPTION" track-by="DESCRIPTION">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} estados seleccionados</span></template>
                            </multiselect>
                        </div>

                        <!-- <div class="item">
                             <vs-select label="Tipo Servicio" v-model="services_types">
                                <vs-select-item :key="index" :value="item.CODE" :text="item.NAME" v-for="(item,index) in srvTypes" />
                            </vs-select>
                        </div> -->

                        <div class="item">
                            <label for="">Estado Servicios</label>
                            <multiselect v-model="services_status" :options="srvStatus" :multiple="true" :max-height="160" placeholder="" open-direction="top"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="NAME" track-by="NAME">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} estados servicios seleccionados</span></template>
                            </multiselect>
                        </div>

                        <div class="item">
                             <vs-select label="Condición Pago" v-model="condPaid">
                                <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in condPayment" />
                            </vs-select>
                        </div>

                        <div class="item">
                             <vs-select label="Programado" v-model="prog">
                                <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in dataYesNo" />
                            </vs-select>
                        </div>

                        <div class="item">
                             <vs-select label="Pagado" v-model="paid">
                                <vs-select-item :key="index" :value="item.code" :text="item.name" v-for="(item,index) in dataYesNo" />
                            </vs-select>
                        </div>

                        <div class="item relative">
                            <div class='autocompleteel'> 
                                <div class="form-row">
                                    <vs-input type="text" label="Proveedor" v-model="searchTextSup" @keyup='loadSuggestions(1)'/>
                                    <div class='suggestionlist' v-if="suggestiondata.length && loadSuggestion == 1">
                                        <ul> 
                                            <li v-for='(item,index) in suggestiondata' @click='itemSelected(index, 1)' :key="index">  {{ item.NAME }} </li> 
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>

                        <div class="item relative">
                            <div class='autocompleteel'> 
                                <div class="form-row">
                                    <vs-input type="text" label="Agencia" v-model="searchText" @keyup='loadSuggestions(2)'/>
                                    <div class='suggestionlist' v-if="suggestiondata.length && loadSuggestion == 2">
                                        <ul> 
                                            <li v-for='(item,index) in suggestiondata' @click='itemSelected(index, 2)' :key="index">  {{ item.NAME }} </li> 
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>

                        <div class="item">
                            <label for="">Agente</label>
                            <multiselect v-model="vendors" :options="consultants" :multiple="true" :max-height="160" placeholder="" open-direction="top"
                                :close-on-select="false" :clear-on-select="false" :preserve-search="false" label="NAME" track-by="NAME">
                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} agentes seleccionados</span></template>
                            </multiselect>
                        </div>
                        
                        <div class="item">
                            <vs-button color="primary"  @click="search()" type="filled" icon="search"></vs-button>
                            <vs-button color="warning"  @click="reset()" type="border" icon="backspace" 
                                v-if="date != '' || reference != ''  || vendors.length > 0 || agencies.length > 0 || stBookings.length > 0 || services_status.length > 0
                                    || services_types != '' || voucher != '' || suppliers.length > 0 "></vs-button>
                        </div>
                    </div>
                    <div class="tags">
                        <div class="wrapper-tags">
                            <template v-for="(item, idx) in agencies">
                                <vs-chip :key="idx" transparent color="primary" closable @click="removeAgency(item, 2)">{{ item.NAME }}</vs-chip>
                            </template>
                            <template v-for="(item, idx) in suppliers">
                                <vs-chip :key="idx" transparent color="primary" closable @click="removeAgency(item, 1)">{{ item.NAME }}</vs-chip>
                            </template>
                        </div>
                    </div>
                </vs-collapse-item>
            </vs-collapse>
        </div>

        <div class="bookings-wrapper" v-if="status == 'done'">
            <table-payment-suppliers :items="bkAnalysis"></table-payment-suppliers>
        </div>

        <modal :show="modalShow" :dark="true" title="Código Acceso">
            <div slot="modal-body">
                <div class="modal-content" style="padding: 35px 35px 15px">
                    <p>Por favor ingresar el código de acceso</p>
                    <br>
                    <vs-input v-model="codeAccess"  :danger="errorCode" danger-text="Código incorrecto" v-on:keyup.enter="validAccess()"/>
                </div>
            </div>
            <div slot="modal-footer">
                <div class="modal-footer">
                    <vs-button color="primary" type="border" @click="validAccess()" :disabled="disabled">Ingresar</vs-button>
                </div>
            </div>
        </modal>

    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
// import pagination from '@/components/Pagination.vue'
import tablePaymentSuppliers from '@/components/Tables/PaymentSuppliers.vue'
import inputDatePicker from '@/components/Inputs/input-date-range.vue'
import BookingService from '@/services/BookingService'
import CatalogService from '@/services/CatalogService'
import modal from "@/components/Modals/Basic.vue";
const  { mapActions: mapAppSettingActions } = createNamespacedHelpers('appSetting')

export default {
    name: "BookingsView",
    components: {
        // pagination,
        modal,
        tablePaymentSuppliers,
        inputDatePicker
    },
    data () {
        return {
            bkAnalysis: [],
            key: 1,
            totalPost: 26,
            date: "",
            value: "All",
            reference: "",
            modalShow: true,
            codeAccess: "",
            errorCode: false,
            status: "",
            disabled: false,
            stBookings: [],
            bookStatus: [],
            types: [],
            vendors: [],
            agencies: [],
            consultants:[],
            searchText: "",
            suggestiondata:[],
            typeDate: "EstmPayDate",
            filterDates: [
                {
                    code: "EstmPayDate",
                    name: "Pago Estimado"
                },
                {
                    code: "TravelDate",
                    name: "Travel Date"
                }
            ],
            totalEstimated: 0,
            totalInv:  0,
            totalPaid: 0,
            pendingCost:  0,
            srvStatus: [],
            srvTypes: [],
            services_status: [],
            services_types: [],
            condPaid: "",
            condPayment: [
                {
                    code: "",
                    name: 'Todos'
                },
                {
                    code: "CD",
                    name: 'Credit'
                },
                {
                    code: "PP",
                    name: 'Prepayment'
                }
            ],
            suppliers:[],
            loadSuggestion: 1,
            searchTextSup: "",
            voucher: "",
            prog: "",
            paid: "",
            dataYesNo: [ { code: "", name: 'Ambos'}, {code: "No", name: 'No'}, {code: "Si", name: 'Si'},]
        }
    },
    async created () {
        let code =  JSON.parse(localStorage.getItem('CDG'));
        this.codeAccess = code
        if(this.codeAccess != "" || this.disabled != undefined){
            this.validAccess();
        }
        this.setDefaultValues();
        await this.getPaymentSuppliers(); 
        await this.getBookingStatus();
        await this.getConsultants();
        await this.getServiceStatus();
        await this.getServiceTypes();
    },
    methods: {
        ...mapAppSettingActions(["LOADING_STATUS"]),
        async getPaymentSuppliers(){            
            this.LOADING_STATUS(true);
            let response = await BookingService.getPaymentSuppliers({
                "begDate" : this.date != '' ? this.date.start : "", //opcional
                "endDate" : this.date != '' ? this.date.end : "",//opcional
                "reference" : this.reference, //opcional
                "agencies" :    this.agencies, //opcional
                "consultants" : this.vendors, //opcional
                "booking_status": this.stBookings,
                "typeDate": this.typeDate,
                "condPaid": this.condPaid,
                "voucher": this.voucher,
                "suppliers": this.suppliers,
                "paid": this.paid,
                "prog": this.prog,
                "services_status": this.services_status
            });            
            if((response.status/100) == 2){
                this.bkAnalysis = response.data
            } 

            this.totalEstimated  = 0;
            this.totalInv   = 0;
            this.totalPaid  = 0;
            this.pendingCost  = 0;
            
            this.bkAnalysis.forEach(booking => {
                this.totalEstimated  = this.totalEstimated + booking.Cost
                this.totalInv     += booking.invoiceAmount
                this.totalPaid    += booking.Pagado == 'Si' ? booking.chequeAmount : 0
                // this.pendingCost  += booking.Pagado == 'No' ? booking.invoiceAmount - booking.chequeAmount : 0
            });           
            this.pendingCost = this.totalInv - this.totalPaid
            this.pendingCost = this.pendingCost > 0 ? this.pendingCost : this.totalEstimated
            this.LOADING_STATUS(false);
        },
        async getBookingStatus(){
            this.LOADING_STATUS(true);
            this.bookStatus = await CatalogService.getBookingStatus({});            
            this.LOADING_STATUS(false);
        },
        async getServiceStatus(){
            this.LOADING_STATUS(true);
            this.srvStatus = await CatalogService.getServiceStatus({});            
            this.LOADING_STATUS(false);
        },
        async getServiceTypes(){
            this.LOADING_STATUS(true);
            this.srvTypes = await CatalogService.getServiceTypes({});            
            this.LOADING_STATUS(false);
        },
        async getConsultants(){
            this.LOADING_STATUS(true);
            this.consultants = await CatalogService.getConsultants({});            
            this.LOADING_STATUS(false);
        },
        handlePagination (_paged) {
            this.filter.page = _paged    
            // this.retrieveProperties()
        },
        search() {
            this.getPaymentSuppliers()
        },
        reset() {
            this.date = "";
            this.reference = "";
            this.agencies  = [];
            this.vendors   = [];
            this.stBookings = [];
            this.suppliers  = [];
            this.voucher    = "";
            this.services_types = "";
            this.setDefaultValues();
            this.getPaymentSuppliers();
        },
        setDefaultValues(){
            this.stBookings = [ 
                { CODE: 'OK', DESCRIPTION: "Confirmed" },
                { CODE: 'IN', DESCRIPTION: "Invoiced" }, 
                { CODE: 'RE', DESCRIPTION: "Reservation"}, 
                { CODE: 'PP', DESCRIPTION: "Partial Pay"}, 
                { CODE: 'PA', DESCRIPTION: "Paid"}
            ];
            this.services_status = [
                // { CODE: 'PE', NAME: "Pending" }, 
                // { CODE: 'RQ', NAME: "Request"} ,
                { CODE: 'OK', NAME: "Confirmed"},
                { CODE: 'CO', NAME: "Reconfirmed"}, 
                { CODE: 'CD', NAME: "Confirmed / Differen"}]
        },
        async loadSuggestions(type){
            this.suggestiondata = [];
            this.loadSuggestion = type;
            if(this.searchText != '' || this.searchTextSup != ''){
                let response = type == 1 ? await CatalogService.getSuppliers({ search: this.searchTextSup}) : await CatalogService.getAgencies({ search: this.searchText})
                console.log('response', response)
                if((response.status/100) == 2){
                    this.suggestiondata = response.data
                    // this.searchText = ''
                }
            }
        },
        itemSelected(index, type){
            if(type == 2){
                if(this.agencies.findIndex(item => item.CODE == this.suggestiondata[index].CODE) < 0){
                    this.agencies.push(this.suggestiondata[index])
                }
            } else {
                if(this.suppliers.findIndex(item => item.CODE == this.suggestiondata[index].CODE) < 0){
                    this.suppliers.push(this.suggestiondata[index])
                }
            }
            this.suggestiondata = [];
            this.searchText = ''
            this.searchTextSup = ''
        },
        removeAgency (item, type) {
            if(type == 1){
                this.suppliers.splice(this.suppliers.indexOf(item), 1)
            }else{
                this.agencies.splice(this.agencies.indexOf(item), 1)
            }
        },
        validAccess() {
            if(this.codeAccess == "asuaire2022") {
                this.modalShow = false
                this.status = "done"
                localStorage.setItem('CDG', JSON.stringify(this.codeAccess))
            } else {
                this.errorCode = true
            }
        },
    }
}
</script>